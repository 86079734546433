<template>
  <div class="pull-right">
    <b-modal
      ref="my-modal"
      title="Мэдээлэл оруулах"
      hide-footer
      @hide="hideCallback"
    >
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity"
        :background-color="this.$config.LOADER.backgroundColor"
        :z-index="10000"
      ></loading>
      <b-form @submit.stop.prevent="save">
        <h4 v-if="sales">
          {{ sales.lastname }} {{ sales.firstname }} <br />
          <small class="text-info">
            <strong>{{ sales.phone }}</strong>
          </small>
        </h4>
        <hr />

        <b-form-group label="Төлөв" label-for="state">
          <b-select v-model="selected_state">
            <option :value="null" disabled>Сонгоно уу</option>
            <option
              :value="item"
              :key="index"
              v-for="(item, index) in this.states"
              >{{ item.name }}</option
            >
          </b-select>
        </b-form-group>

        <!-- <b-form-group label="Огноо" label-for="input-body" v-if="selected_type_success">
                    <date-picker v-model="form.date" :config="{format:'YYYY-MM-DD', useCurrent: false}" placeholder="Сонгоно уу"></date-picker>
                </b-form-group>
                <b-form-group label="Хаяг" label-for="input-body" v-if="selected_type_success">
                    <b-form-textarea v-model="form.address" placeholder=""></b-form-textarea>
                </b-form-group> -->

        <b-form-group label="Нэмэлт тайлбар" label-for="input-body">
          <b-form-textarea
            v-model="form.description"
            placeholder=""
          ></b-form-textarea>
        </b-form-group>
        <div class="pull-right">
          <b-button block type="submit" variant="primary">Хадгалах</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
export default {
  name: "Sales.New.Create",
  components: { datePicker },
  props: ["sales", "callback", "states", "hideCallback"],
  data: function() {
    return {
      isLoading: false,
      parent: null,
      form: {
        stateId: null,
        type: null,
        description: "",
      },
      selected_state: null,
      selected_type_success: false,
      note_types: [],
    };
  },
  watch: {
    sales: {
      handler(sales) {
        //console.log(this.sales)
        if (sales) {
          this.showModal();
        }
      },
      deep: true,
    },
    selected_state: {
      handler(selected_state) {
        if (selected_state) {
          this.$data.form.stateId = selected_state.id;
          // this.$data.selected_type_success = selected_state.isSuccess;
        } else {
          this.$data.form.stateId = null;
          // this.$data.selected_type_success = false
        }
      },
      deep: true,
    },
  },
  methods: {
    save: function() {
      if (this.$data.form.stateId == null) {
        this.showToast("Анхаар", "Төлөв сонгоогүй байна", "warning");
      } else if (
        this.$data.selected_type_success &&
        (this.$data.form.date == null || this.$data.form.date == "")
      ) {
        this.showToast("Анхаар", "Огноо сонгоогүй байна", "warning");
      } else if (
        this.$data.selected_type_success &&
        (this.$data.form.address == null || this.$data.form.address == "")
      ) {
        this.showToast("Анхаар", "Хаяг оруулаагүй байна", "warning");
      } else {
        this.isLoading = true;
        this.$data.form.token = this.$store.getters.token;
        this.$data.form.email = this.$store.getters.email;
        this.$data.form.id = this.sales.id;

        this.$http
          .post(
            this.$config.API_URL + "SalesWebService/loan_officer_data_change",
            { data: JSON.stringify(this.$data.form) },
            { headers: this.$store.getters.httpHeader, emulateJSON: true }
          )
          .then(
            (response) => {
              //console.log(response)
              this.isLoading = false;
              if (response.body.responseResultType == "SUCCESS") {
                this.showToast(
                  "Амжилттай",
                  "Амжилттай хадгалагдлаа",
                  "success"
                );
                this.hideModal();
                this.callback();
              } else {
                let _ms = response.body.failureMessages.message;
                for (var i in _ms) {
                  this.showToast("Анхаар", _ms[i].failureMessage, "warning");
                }
              }
            },
            (response) => {
              //console.log(response)
              this.isLoading = false;
              this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
            }
          );
      }
    },
    showModal() {
      if (this.sales.operatorStateId) {
        for (var i in this.states) {
          if (this.states[i].id == this.sales.operatorStateId) {
            this.$data.selected_state = this.states[i];
          }
        }
      } else {
        this.$data.selected_state = null;
      }

      this.$data.form = {
        stateId: this.sales.operatorStateId,
        description: this.sales.operatorDescription,
      };
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
  },
};
</script>
